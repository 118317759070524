import { decode } from 'html-entities';

interface ITillrAccountsModel {
  page: string;
  clientId: string;
  viewModel?: Partial<ViewModel>;
}

interface ViewModel {
  clientName: string;
  redirectUrl: string;
  loginUrl: string;
  antiForgery: {
    name: string;
    value: string;
  };
  allowRememberMe: boolean;
  rememberMe: boolean;
  errorMessage: string;
  siteUrl: string;
  siteName: string;
  logoutUrl: string;
}

const TillrAccountsModel = JSON.parse(
  decode(document.getElementById('TillrAccountsModel')?.textContent)
) as ITillrAccountsModel;

export default TillrAccountsModel;
