import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { getAppInsightsConnectionString, getUrlPrefix } from './utils';

const appInsights = new ApplicationInsights({
  config: {
    connectionString: getAppInsightsConnectionString(getUrlPrefix()),
  },
});
appInsights.loadAppInsights();

export { appInsights };
