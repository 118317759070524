import { PropsWithChildren } from 'react';
import { getReturnUrl } from '../utils';

interface IProps {
  subdomain?: string | null;
  isTillr3?: boolean;
}

export function ReturnButton(props: PropsWithChildren<IProps>) {
  const { subdomain, isTillr3, children } = props;

  const handleClick = () => {
    window.location.href = getReturnUrl(subdomain, isTillr3);
  };

  return (
    <button type="button" onClick={handleClick}>
      {children}
    </button>
  );
}
