import { useState } from 'react';
import './PasswordInput.css';

interface IProps {
  id: string;
  required?: boolean;
  onChange: (value: string) => void;
}

export function PasswordInput(props: IProps) {
  const { id, required, onChange } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="input-group password-input">
      <input
        type={showPassword ? 'text' : 'password'}
        id={id}
        name={id}
        autoComplete="off"
        required={required}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      <div
        className={showPassword ? 'hide' : 'show'}
        onClick={() => setShowPassword((prev) => !prev)}
      >
        <i></i>
      </div>
    </div>
  );
}
