import { ReturnButton } from '../components/ReturnButton';

export function UnknownClientPage() {
  return (
    <section id="registration" className="dialog__wrapper">
      <div className="dialog">
        <div className="dialog__content">
          <h2>Error</h2>
          <p>We were unable to determine the address you are trying to reach.</p>
          <p>
            If you copy and pasted a link, please check you have the full address. If clicking on a
            link in an email, please contact support@tillr.io and we will try to help.
          </p>
          <p>If none of this makes any sense, try following the link below.</p>
        </div>
        <div className="dialog__controls">
          <ReturnButton>continue</ReturnButton>
        </div>
      </div>
    </section>
  );
}
