import { ReturnButton } from '../components/ReturnButton';
import TillrAccountsModel from '../TillrAccountsModel';

const NON_TILLR_CLIENTS = ['Licensing SAVI'];

export function LoggedOutPage() {
  let clientName = TillrAccountsModel.viewModel?.clientName ?? '';
  if (!NON_TILLR_CLIENTS.includes(clientName)) {
    clientName = 'Tillr';
  }

  return (
    <section id="logged-out-message" className="dialog__wrapper">
      <div className="dialog">
        <div className="dialog__content">
          <h3>See you soon!</h3>
          <p>You have now logged out of {clientName}.</p>
        </div>
        <div className="dialog__controls">
          <ReturnButton>SIGN IN</ReturnButton>
        </div>
      </div>
    </section>
  );
}
