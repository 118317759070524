export function LoadingPage() {
  return (
    <div id="tillr-loading">
      <div className="tillr-loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
