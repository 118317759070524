import { useEffect, useState } from 'react';
import { PageProvider } from './PageProvider';
import { LoadingPage } from './Pages/LoadingPage';
import { PageSwitch } from './Pages/PageSwitch';
import { getReturnUrl, isAzureWebsitesDomain } from './utils';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });

  if (isAzureWebsitesDomain()) {
    window.location.href = getReturnUrl();
    return <p>Redirecting.</p>;
  }

  return (
    <>
      {isLoading && <LoadingPage />}

      <div className={isLoading ? 'page-loading' : 'fade-in'}>
        <PageProvider>
          <PageSwitch />
        </PageProvider>
      </div>

      <footer className={isLoading ? '' : 'fade-in'}>
        <p>Copyright &copy; Telamon Software Ltd. All rights reserved.</p>
      </footer>
    </>
  );
}

export default App;
