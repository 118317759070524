import TillrAccountsModel from './TillrAccountsModel';

export function isAzureWebsitesDomain(): boolean {
  return window.location.hostname.endsWith('.azurewebsites.net');
}

export function getUrlPrefix(): string {
  if (isAzureWebsitesDomain()) {
    // tillr-accounts-test.azurewebsites.net or tillr-accounts.azurewebsites.net
    return window.location.hostname.split('.')[0].split('-')[2] ?? '';
  }

  if (window.location.hostname.startsWith('local')) {
    // e.g. localhost:3000, localmy3.tillr.io
    return 'local';
  }
  if (window.location.hostname.startsWith('test')) {
    return 'test';
  }
  return '';
}

export function getApiBaseUrl(): string {
  return `https://${getUrlPrefix()}accounts.tillr.io/api`;
}

export function getReturnUrl(
  subdomain?: string | null,
  isTillr3?: boolean,
  accessToken?: string | null
): string {
  if (TillrAccountsModel.viewModel?.redirectUrl) {
    return new URL(TillrAccountsModel.viewModel.redirectUrl).origin;
  }

  if (
    subdomain === undefined &&
    document.referrer?.trim() &&
    !document.referrer.includes('accounts.tillr.io')
  ) {
    return document.referrer;
  }

  const domain = `https://${getUrlPrefix()}${subdomain ?? 'my'}.tillr.io`;
  const path = isTillr3 ? '/authenticate' : accessToken ? '/#!/registration' : '';
  const query = accessToken ? `?login_hint=${accessToken}` : '';
  return domain + path + query;
}

export function getAppInsightsConnectionString(urlPrefix: string): string {
  switch (urlPrefix) {
    case '':
      return 'InstrumentationKey=258ba611-82ba-40c0-9b22-b21a98904e0d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=0762ca37-fa80-4508-8166-79264cc6773a';
    case 'test':
      return 'InstrumentationKey=2ce03214-a4eb-46c6-8814-fdfda800187d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=5a6782c3-aa6e-4f13-b0b6-eddfb52814c1';
    case 'local':
    default:
      return 'InstrumentationKey=1203ad37-9546-412f-8ff0-64cf3ead7d6e;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=da47ba4b-82d4-425c-90ac-b46ea982b0de';
  }
}

export function getErrorMessage(
  response: Response
): Promise<{ errorMessage: string; showUser: boolean }> {
  return new Promise<{ errorMessage: string; showUser: boolean }>((resolve) => {
    response
      .json()
      .then(({ Message }) => resolve({ errorMessage: Message, showUser: response.status < 500 }))
      .catch(() =>
        response.text().then((content) => resolve({ errorMessage: content, showUser: false }))
      );
  });
}

export function parseUrl(
  hash: string
): [tenantId: string | null, guid: string | null, accessToken: string | null] {
  try {
    const split = hash.split('/')[2].split(/[_?]/);
    if (split.length >= 2) {
      const accessToken = new URLSearchParams(hash.split('?')[1]).get('access_token');
      return [split[0], split[1], accessToken];
    }
  } catch {}
  return [null, null, null];
}

export function clearRememberMeData() {
  window.localStorage.removeItem('tillr-remember-me');
}

export function getRememberMeData(): string | null {
  return window.localStorage.getItem('tillr-remember-me');
}

export function setRememberMeData(email: string) {
  window.localStorage.setItem('tillr-remember-me', email);
}

export function validateEmail(value: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
}
