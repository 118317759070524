import { ReturnButton } from '../components/ReturnButton';
import TillrAccountsModel from '../TillrAccountsModel';

export function ErrorPage() {
  return (
    <>
      <h1 id="title">Tillr Accounts</h1>

      <section id="error-message" className="dialog__wrapper">
        <div className="dialog">
          <div className="dialog__content">
            <h3>Error</h3>
            <div>
              <p>{TillrAccountsModel.viewModel?.errorMessage ?? 'Something has gone wrong.'}</p>
            </div>
            <p>Please try again.</p>
          </div>
          <div className="dialog__controls">
            <ReturnButton>SIGN IN</ReturnButton>
          </div>
        </div>
      </section>
    </>
  );
}
