import { usePageProvider } from '../PageProvider';
import { ErrorPage } from './ErrorPage';
import { ForgottenPasswordPage } from './ForgottenPasswordPage';
import { LoadingPage } from './LoadingPage';
import { LogInPage } from './LogInPage';
import { LoggedOutPage } from './LoggedOutPage';
import { RegistrationPage } from './RegistrationPage';
import { SetPasswordPage } from './SetPasswordPage';

export function PageSwitch() {
  const { page } = usePageProvider();

  switch (page) {
    case 'login': {
      return <LogInPage />;
    }
    case 'forgottenpassword': {
      return <ForgottenPasswordPage />;
    }
    case 'setpassword': {
      return <SetPasswordPage />;
    }
    case 'logout': {
      return <LoadingPage />;
    }
    case 'loggedOut': {
      return <LoggedOutPage />;
    }
    case 'invitation': {
      return <RegistrationPage type="invitation" />;
    }
    case 'verify': {
      return <RegistrationPage type="signup" />;
    }
    case 'error':
    default: {
      return <ErrorPage />;
    }
  }
}
