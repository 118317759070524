import { PropsWithChildren, createContext, useContext, useState } from 'react';
import TillrAccountsModel from './TillrAccountsModel';

export type Page =
  | 'login'
  | 'logout'
  | 'loggedOut'
  | 'invitation'
  | 'registration'
  | 'verify'
  | 'error'
  | 'forgottenpassword'
  | 'setpassword';

interface IContext {
  page: Page;
  setPage: (page: Page) => void;
}

const PageProviderContext = createContext<IContext | undefined>(undefined);

export function PageProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const initialPage: Page = (
    window.location.hash.startsWith('#!/')
      ? window.location.hash.split('/')[1]
      : TillrAccountsModel.page
  ) as Page;

  const [page, setPage] = useState<Page>(initialPage);

  return (
    <PageProviderContext.Provider value={{ page, setPage }}>
      {children}
    </PageProviderContext.Provider>
  );
}

export function usePageProvider(): IContext {
  const authContext = useContext(PageProviderContext);

  if (!authContext) {
    throw new Error('usePageProvider must be used inside an PageProvider.');
  }

  return authContext;
}
