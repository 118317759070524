import { FormEvent, useState } from 'react';
import { PageButton } from '../components/PageButton';
import { ReturnButton } from '../components/ReturnButton';
import { getApiBaseUrl, validateEmail } from '../utils';

export function ForgottenPasswordPage() {
  const [email, setEmail] = useState('');

  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError('Error - Invalid or empty email address.');
      return;
    }

    setSubmitting(true);

    const url = `${getApiBaseUrl()}/PasswordResetRequests`;
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        Email: email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setSuccess(true);
        } else {
          setError("Error - There's been an error. Please try again later.");
        }
      })
      .finally(() => setSubmitting(false));
  };

  if (success) {
    return (
      <section className="dialog__wrapper">
        <div className="dialog">
          <div className="dialog__content">
            <h3>Request received</h3>
            <p>
              If your email address was recognised, you should receive a password reset email
              shortly. Please ensure you check your inbox and/or junk mail.
            </p>
          </div>
          <div className="dialog__controls">
            <ReturnButton>OK, THANKS</ReturnButton>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section>
      <form
        noValidate
        id="request-form"
        onSubmit={handleSubmit}
        className={submitting ? 'pending' : ''}
      >
        <div className="progress"></div>
        <h1 id="title">Tillr Accounts</h1>
        <div className="message">
          <p>
            Enter your registered email address, and we'll send you a link where you can reset your
            password.
          </p>
        </div>
        {error && (
          <div className="message error">
            <p>{error}</p>
          </div>
        )}
        <fieldset>
          <div className="form-group">
            <label htmlFor="request-email">Email address</label>
            <input
              type="text"
              id="request-email"
              name="request-email"
              required
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
        </fieldset>
        <div className="form-group">
          <button id="request" className="primary" type="submit" disabled={submitting}>
            Send reset password link
          </button>
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <PageButton to="login">Return to login form</PageButton>
        </div>
      </form>
    </section>
  );
}
