import { FormEvent, useState } from 'react';
import { appInsights } from '../appInsights';
import { PasswordInput } from '../components/PasswordInput';
import { ReturnButton } from '../components/ReturnButton';
import { PasswordResetSuccess } from '../types';
import { getApiBaseUrl, getErrorMessage, parseUrl } from '../utils';
import { UnknownClientPage } from './UnknownClientPage';

export function SetPasswordPage() {
  const [tenantId, passwordResetToken] = parseUrl(window.location.hash);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [error, setError] = useState('');
  const [serverError, setServerError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState<PasswordResetSuccess>();

  if (!tenantId) {
    return <UnknownClientPage />;
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!newPassword || newPassword !== confirmPassword) {
      setError('Error - Your password is invalid, or your entered passwords do not match.');
      return;
    }

    setSubmitting(true);

    const url = `${getApiBaseUrl()}/SetPassword`;
    fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        tenantId,
        passwordResetToken,
        newPassword,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(setPasswordResetSuccess);
        } else {
          getErrorMessage(response).then(({ errorMessage, showUser }) => {
            if (showUser) {
              setServerError(errorMessage);
            } else {
              appInsights.trackException({ exception: new Error(errorMessage) });
              setServerError('An unexpected error has occurred.');
            }
          });
        }
      })
      .finally(() => setSubmitting(false));
  };

  if (passwordResetSuccess) {
    return (
      <section className="dialog__wrapper">
        <div className="dialog">
          <div className="dialog__content">
            <h2>Success</h2>
            <p>Your new password has been saved, and you can now use it to sign in.</p>
          </div>
          <div className="dialog__controls">
            <ReturnButton
              subdomain={passwordResetSuccess.TenantSubdomain}
              isTillr3={passwordResetSuccess.TenantIsTillr3}
            >
              Sign In
            </ReturnButton>
          </div>
        </div>
      </section>
    );
  }

  if (serverError) {
    return (
      <section className="dialog__wrapper">
        <div className="dialog">
          <div className="dialog__content">
            <h2>Error</h2>
            <p>{serverError}</p>
            <p>If still required, please try requesting another password reset email.</p>
          </div>
          <div className="dialog__controls">
            <ReturnButton>OK</ReturnButton>
          </div>
        </div>
      </section>
    );
  }

  return (
    <form noValidate id="sp-form" onSubmit={handleSubmit} className={submitting ? 'pending' : ''}>
      <section id="sp-wrapper">
        <div className="progress"></div>
        <h1 id="title">Tillr Accounts</h1>
        <div className="heading">
          <h2>Reset your password</h2>
          <p>Enter and confirm your new password below.</p>
        </div>
        {error && (
          <div className="message error">
            <p>{error} </p>
          </div>
        )}
        <fieldset>
          <div className="form-group">
            <label htmlFor="sp-password">New password</label>
            <PasswordInput id="sp-password" required onChange={setNewPassword} />
          </div>
          <div className="form-group">
            <label htmlFor="sp-confirm-password">Confirm new password</label>
            <PasswordInput id="sp-confirm-password" required onChange={setConfirmPassword} />
          </div>
        </fieldset>
        <div className="form-group">
          <button type="submit" disabled={submitting}>
            Submit
          </button>
        </div>
      </section>
    </form>
  );
}
