import { PropsWithChildren } from 'react';
import { Page, usePageProvider } from '../PageProvider';
import './PageButton.css';

interface IProps {
  to: Page;
}

export function PageButton(props: PropsWithChildren<IProps>) {
  const { to, children } = props;

  const { setPage } = usePageProvider();

  const handleClick = () => {
    setPage(to);
  };

  return (
    <button className="page-button" onClick={handleClick}>
      {children}
    </button>
  );
}
