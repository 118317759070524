import { FormEventHandler, useEffect, useRef, useState } from 'react';
import { appInsights } from '../appInsights';
import { PageButton } from '../components/PageButton';
import { PasswordInput } from '../components/PasswordInput';
import TillrAccountsModel from '../TillrAccountsModel';
import { Domain } from '../types';
import {
  clearRememberMeData,
  getApiBaseUrl,
  getErrorMessage,
  getRememberMeData,
  setRememberMeData,
  validateEmail,
} from '../utils';

export function LogInPage() {
  const formRef = useRef<HTMLFormElement>(null);

  const rememberMeData = getRememberMeData();

  const [email, setEmail] = useState(rememberMeData ?? '');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(Boolean(rememberMeData));

  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [domains, setDomains] = useState<Array<Domain>>();
  const [tenantId, setTenantId] = useState<number>();

  const handleGetUserDomains: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!validateEmail(email) || !password) {
      setError('Error - Invalid or empty email address or password.');
      return;
    }

    setError('');
    setSubmitting(true);

    if (rememberMe) {
      setRememberMeData(email);
    } else {
      clearRememberMeData();
    }

    fetch(`${getApiBaseUrl()}/GetUserDomains`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        Email: email,
        Password: password,
        ClientId: TillrAccountsModel.clientId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((domains: Array<Domain>) => {
            if (!domains.length) {
              setError("Error - There's been an error. Please try again later.");
            } else if (domains.length === 1 || document.referrer.split('.')[0].match('hq')) {
              setTenantId(domains[0].TenantId);
            } else {
              setDomains(domains);
            }
          });
        } else {
          getErrorMessage(response).then(({ errorMessage, showUser }) => {
            if (showUser) {
              setError(errorMessage);
            } else {
              appInsights.trackException({ exception: new Error(errorMessage) });
              setError("There's been an error. Please try again later.");
            }
          });
        }
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (tenantId && formRef.current) {
      formRef.current.submit();
      setSubmitting(true);
    }
  }, [tenantId]);

  return (
    <form
      action={TillrAccountsModel.viewModel?.loginUrl}
      method="POST"
      noValidate
      ref={formRef}
      className={submitting ? 'pending' : ''}
      onSubmit={!tenantId ? handleGetUserDomains : undefined}
    >
      {/* hidden so the form is still submittable from the DOM */}
      <section id="login-wrapper" className={domains ? 'hidden' : ''}>
        <div className="progress"></div>
        <h1 id="title">Tillr Accounts</h1>
        {TillrAccountsModel.viewModel?.antiForgery && (
          <input
            type="hidden"
            name={TillrAccountsModel.viewModel.antiForgery.name}
            value={TillrAccountsModel.viewModel.antiForgery.value}
          />
        )}
        <input type="hidden" name="clientId" value={TillrAccountsModel.clientId} />
        <input type="hidden" name="username" value={`${tenantId}|${email}`} />
        {error && (
          <div className="message error">
            <p>{error}</p>
          </div>
        )}

        <fieldset>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="text"
              name="email"
              required
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <PasswordInput id="password" required onChange={setPassword} />
          </div>
          <div className="form-group">
            <div className="checkbox">
              <label htmlFor="remember">
                <input
                  type="checkbox"
                  id="remember"
                  name="remember"
                  className="magic-checkbox"
                  onChange={(e) => setRememberMe(e.currentTarget.checked)}
                />
                <label htmlFor="remember"></label>
                Remember me?
              </label>
            </div>
          </div>
        </fieldset>
        <div className="form-group">
          <button id="sign-in" type="submit" className="primary" disabled={submitting}>
            Sign in
          </button>
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <PageButton to="forgottenpassword">Forgotten your password?</PageButton>
        </div>
      </section>

      {domains && (
        <section id="domains-wrapper">
          <div className="progress"></div>
          <h1 id="title">Tillr Accounts</h1>
          <h2>Choose an account</h2>
          <ol>
            {domains
              .sort((a, b) => a.Name.localeCompare(b.Name))
              .map(({ TenantId, Name }) => (
                <li key={TenantId}>
                  <button type="button" onClick={() => setTenantId(TenantId)}>
                    <span>{Name}</span> <i></i>
                  </button>
                </li>
              ))}
          </ol>
        </section>
      )}
    </form>
  );
}
